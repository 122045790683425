import axios from 'axios'
import tokens from './tokens'

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.response.use(
  response => {
    if (process.env.NODE_ENV === 'development') {
      console.log(response)
    }

    return response
  },

  error => {
    const res = error.response
    if (res.status === 401) {
      // window.location.href = “https://example.com/login”;
      // Do I need to pass the router in?

      console.log('Redirect to login')
    }

    // ToDo: possibly this is where the refresh token goes here

    console.error(`Looks like there was a problem. Status Code: ${res.status}`)

    return Promise.reject(error)
  },
)

function post(url, payload) {
  const token = tokens.getAuth()

  return axiosClient.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function get(url, responseType = 'json') {
  const token = tokens.getAuth()

  return axiosClient.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType,
  })
}

function put(url, payload) {
  const token = tokens.getAuth()

  return axiosClient.put(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function remove(url) {
  const token = tokens.getAuth()

  return axiosClient.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export default {
  post,
  get,
  put,
  remove,
}
