import Vue from 'vue'
import Vuex from 'vuex'
import projects from '@/store/modules/project'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
  },
  mutations: {
    setLoginStatus(state, payload) {
      state.loggedIn = payload
    },
  },
  actions: {
    loginUser(context) {
      context.commit('setLoginStatus', true)
    },
    logoutUser(context) {
      context.commit('setLoginStatus', false)
    },
  },
  modules: {
    project: projects,
  },
})
