function store(tokens) {
  // Temporary solution for refresh
  localStorage.setItem(process.env.VUE_APP_ROOT_TOKEN_LOCATION, tokens.token)
  localStorage.setItem(process.env.VUE_APP_ROOT_REFRESH_TOKEN_LOCATION, tokens.refresh_token)
}

function getAuth() {
  return localStorage.getItem(process.env.VUE_APP_ROOT_TOKEN_LOCATION)
}

function getRefresh() {
  return localStorage.getItem(process.env.VUE_APP_ROOT_REFRESH_TOKEN_LOCATION)
}

export default {
  store,
  getAuth,
  getRefresh,
}
