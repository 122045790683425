import calls from './calls'

function logError(err) {
  console.error(err)
}

async function list() {
  let projectList = []
  const url = '/api/project/'
  try {
    const callResult = await calls.get(url)
    projectList = callResult.data
  } catch (error) {
    logError(error)
  }

  return projectList
}

async function load(id) {
  let project = {}
  const url = `/api/project/${id}`
  try {
    const callResult = await calls.get(url)
    project = callResult.data
  } catch (error) {
    logError(error)
  }

  return project
}

async function edit(project) {
  let result = {}
  const url = `/api/project/${project.id}`
  try {
    result = await calls.put(url, project)
  } catch (error) {
    logError(error)
  }

  return result
}

async function remove(id) {
  let result = {}
  const url = `/api/project/${id}`
  try {
    result = await calls.delete(url)
  } catch (error) {
    logError(error)
  }

  return result
}

async function add(project) {
  let newProject = {}
  const url = '/api/project/'
  try {
    const callResult = await calls.post(url, project)
    newProject = callResult.data
  } catch (error) {
    logError(error)
  }

  return newProject
}

async function setcover(id, mediaID) {
  let result = {}
  const url = `/api/project/${id}/setcover/`
  try {
    result = await calls.post(url, { mediaID })
  } catch (error) {
    logError(error)
  }

  return result
}

export default {
  list,
  load,
  edit,
  remove,
  add,
  setcover,
}
