import projectAPI from '@/library/project'

const state = {
  list: [{
    id: 1541,
    title: 'Project from Store',
    description: 'This is the description of the project in the store',
  },
  {
    id: 2541,
    title: 'Second Project from Store',
    description: '2: This is the description of the project in the store',
  }],
  isLoading: true,
  isProcessing: true,
  name: 'Boucher',
  old: {
    id: 1111,
    title: 'Ye Olde Project from Store',
    description: 'This is the description of the project in the store',
    motivation: 'This is my motivation',
    visibility: 'Public',
    stars: 75,
  },
  current: {},
}

const getters = {
  current: () => state.current,
  loading: () => state.isLoading,
  processing: () => state.isProcessing,
  thename: () => state.name,
}

const mutations = {
  loaded(xstate, payload) {
    // eslint-disable-next-line no-param-reassign
    xstate.current = payload
  },
  setLoading(xstate, flag) {
    // eslint-disable-next-line no-param-reassign
    xstate.isLoading = flag
  },
  setProcessing(xstate, flag) {
    // eslint-disable-next-line no-param-reassign
    xstate.isProcessing = flag
  },
}

const setters = {}

const actions = {
  load: async (context, id) => {
    context.commit('setLoading', true)
    const returnedProject = await projectAPI.load(id.value)
    context.commit('loaded', returnedProject)
    context.commit('setLoading', false)
  },
  add: async (context, newProject) => {
    context.commit('setProcessing', true)
    const returnedProject = await projectAPI.add(newProject)
    context.commit('loaded', returnedProject)
    context.commit('setProcessing', false)
  },
  refresh: async context => {
    const returnedProject = await projectAPI.load(state.current.id)
    context.commit('loaded', returnedProject)
  },
  setcover: async (context, mediaID) => {
    context.commit('setProcessing', true)
    const response = await projectAPI.setcover(state.current.id, mediaID)
    if (response) {
      context.commit('setProcessing', false)
    }
  },
}

export default {
  namespaced: true,
  getters,
  setters,
  actions,
  mutations,
  state,
}
